<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton
              :showBtn="false"
              :showAddNew="false"
              title="Product Import List"
            />
            <input type="file" class="d-none" ref="uploader" @change="handleFileUpload">
        </div>

        <div class="row p-2">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label class="form-label" for="select2-basic">Product Type</label>
                <v-select
                    v-model="product_type"
                    :options="productTypeArray"
                    label="value"
                    :reduce="value => value.key"
                />
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label class="form-label" for="select2-basic">Product & Service Group</label>
                <v-select
                    v-model="product_head_id"
                    :options="productGroup"
                    label="name"
                    :reduce="name => name.id"
                />
            </div>

            <div v-if="product_type && product_head_id" class="col-12 col-sm-6 col-md-4 col-lg-2 mt-auto">
                <button @click="uploadCsv" class="btn btn-primary waves-effect waves-float waves-light">
                    Browse
                </button>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-auto">
                <button @click="getCsvRow" class="btn btn-primary waves-effect waves-float waves-light">
                    Get CSV
                </button>
            </div>
        </div>

        <div class="p-2">
            <div v-for="(item, index) in csvRow" :key="index">
                <div class="py-1 bg-black-light rounded-8 mb-2 d-flex justify-content-between align-items-center">
                    <span class="cursor-pointer px-1" @click="gotoUpload(item.id)">id: {{ item.id }} Dataset: {{ item.batch }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, watch, inject } from 'vue';
    import {useRoute, useRouter} from 'vue-router';
    import handleInventory from "@/services/modules/inventory";
    import TitleButton from '@/components/atom/TitleButton.vue';

    const route = useRoute();
    const router = useRouter();
    const { uploadCsvFile, fetchGroup, fetchHome, fetchCsvRow } = handleInventory();

    const selectedFile = ref(null);
    const fileName = ref('');
    const uploader = ref(null);
    const uploadLog = ref([]);
    const product_head_id = ref(null);
    const productGroup = ref([]);
    const productTypeArray = ref([]);
    const product_type = ref(null);
    const csvRow = ref([]);
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    onMounted(() => {
        Promise.all([
            fetchHome().then((res) => {
                if(res.status) {
                    let productType = res.data.product_type
                    productTypeArray.value = Object.keys(productType).map(key => ({
                                                key,
                                                value: productType[key],
                                            }));
                }
            }),
            getCsvRow()
        ]).catch(e => showError('Something went wrong'))
    })

    watch(product_type, (newValue) => {
        fetchGroup(route.params.companyId, newValue).then((res) => {
            productGroup.value = res.data
        }).catch(e => showError('Something went wrong'));
    })

    const uploadCsv = () => {
        uploader.value.click();
    }

    const getCsvRow = () => {
        let query = `?company_id=${route.params.companyId}`;

        fetchCsvRow(query).then((res) => {
            if(!res.status) {
                showError(res.message)
                return
            }
            showSuccess(res.message)
            csvRow.value = res.data
        }).catch(e => showError('Something went wrong'));
    }

    const gotoUpload = (id) => {
        route.query.rowId = id;
        router.push({name: 'upload-product', params: route.params, query: route.query});
    }

    const handleFileUpload = (event) => {
        selectedFile.value = event.target.files[0];
        fileName.value = event.target.files[0].fileName

        let fd = new FormData();
        fd.append('company_id', route.params.companyId)
        fd.append('sheet', selectedFile.value)
        fd.append('product_head_id', product_head_id.value)
        fd.append('type', 'product')
        
        uploadCsvFile(fd).then((res) => {
            if(!res.status) {
                showError(res.message)
                return
            }
            
            showSuccess(res.message)
            uploadLog.value = res.data
        }).catch(e => showError('Something went wrong'));
    }
</script>